import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { contractTypes } from '../../../../ClientContract/constants/contractTypes';

const useClientOfferOverlayValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				offerDate: yup.date().typeError(t('Offer Date is required')).required(t('Offer Date is required')),
				systemSize: yup
					.number()
					.typeError(t('System size must be a number'))
					.positive(t('System size must be positive'))
					.test(
						'decimal-places',
						'System size must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('System size is required'))
					.nullable(),
				epcVolume: yup
					.number()
					.typeError(t('EPC Volume must be a number'))
					.test(
						'decimal-places',
						'EPC Volume must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				currency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.default(null)
					.typeError(t('Currency is required'))
					.required(t('Currency is required')),
				epcVatApplicable: yup.bool(),
				epcOffer: yup.string(),
				yield: yup
					.number()
					.typeError(t('Yield must be a number'))
					.positive(t('Yield must be positive'))
					.test(
						'decimal-places',
						'Yield must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				p90: yup.bool(),
				solarPvDegradation: yup
					.number()
					.typeError(t('SaaS degradation rate must be a number'))
					.min(0, t('SaaS degradation rate must not be less than 0'))
					.max(100, t('SaaS degradation rate must not be more than 100'))
					.test(
						'decimal-places',
						'SaaS degradation rate must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				mountingTypes: yup
					.array()
					.of(yup.object().shape({ label: yup.string(), value: yup.string() }))
					.nullable(),
				annualOmPrice: yup
					.number()
					.typeError(t('Annual O&M Price must be a number'))
					.min(0, t('Annual O&M Price must not be less than 0'))
					.test(
						'decimal-places',
						'Annual O&M Price must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				annualOmEscalationRate: yup
					.number()
					.typeError(t('Annual O&M escalation rate must be a number'))
					.min(0, t('Annual O&M escalation rate must not be less than 0'))
					.max(100, t('Annual O&M escalation rate must not be more than 100'))
					.test(
						'decimal-places',
						'Annual O&M escalation rate must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				extraCapexExpected: yup
					.number()
					.typeError(t('Extra CAPEX Expected must be a number'))
					.min(0, t('Extra CAPEX Expected must not be less than 0'))
					.test(
						'decimal-places',
						'Extra CAPEX Expected must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				extraOpexExpected: yup
					.number()
					.typeError(t('Extra OPEX must be a number'))
					.min(0, t('Extra OPEX expected must not be less than 0'))
					.test(
						'decimal-places',
						'Extra OPEX Expected must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				contractType: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.test('required', 'Contract Type is required', value => value && value.label && value.value)
					.nullable(),
				contractTerm: yup
					.number()
					.typeError(t('Contract Term must be a number'))
					.integer(t('Contract Term must be a whole number'))
					.positive(t('Contract Term must be positive'))
					.min(3, 'Contract Term must be more than or equal to 3')
					.max(25, 'Contract Term must be less than or equal to 25')
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required('Contract Term is required'),
				escalationRateInPercent: yup
					.number()
					.typeError(t('Escalation rate must be a number'))
					.positive(t('Escalation rate must be positive'))
					.min(0, t('Escalation rate must be greater than or equal to 0'))
					.max(100, t('Escalation rate must be less than or equal to 100'))
					.test(
						'decimal-places',
						'Escalation rate must have no more than 2 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				ppaTariffType: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.test('required', 'PPA Tariff Type is required', (value, { parent: { contractType } }) =>
						contractType.label === contractTypes.PPA ? value && value.label && value.value : true,
					)
					.nullable(),
				saasCurrency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.default(null)
					.typeError(t('SaaS Currency is required'))
					.required('SaaS Currency is required'),
				solarUtilizationRate: yup
					.number()
					.typeError(t('Solar Utilization Rate must be a number'))
					.min(0, t('Solar Utilization Rate must not be less than 0'))
					.max(100, t('Solar Utilization Rate must be less than or equal to 100'))
					.test(
						'decimal-places',
						'Solar Utilization Rate must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				minimumPurchaseAmount: yup
					.number()
					.typeError(t('Minimum purchase amount must be a number'))
					.positive('Minimum purchase amount must be positive')
					.test(
						'decimal-places',
						'Minimum purchase amount must have no more than 3 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				discountOnGridTariff: yup
					.number()
					.typeError(t('SaaS floating discount rate must be a number'))
					.min(0, t('SaaS floating discount must be positive'))
					.test(
						'decimal-places',
						'SaaS floating discount must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				clientDownpayment: yup
					.number()
					.typeError(t('Client Downpayment must be a number'))
					.min(0, t('Client Downpayment must be greater than or equal to 0'))
					.test(
						'decimal-places',
						'Client Downpayment must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				billingPeriod: yup
					.number()
					.typeError(t('Billing period must be a number'))
					.positive(t('Billing period must be positive'))
					.integer(t('Billing period must be a whole number'))
					.max(12, t('Billing period must be less than or equal to 12'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				leaseFee: yup
					.number()
					.typeError(t('Lease Fee must be a number'))
					.positive('Lease Fee must be positive')
					.test(
						'decimal-places',
						'Lease Fee must have no more than 3 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				ppaTariff: yup
					.number()
					.typeError(t('PPA Tariff must be a number'))
					.positive('PPA Tariff must be positive')
					.test(
						'decimal-places',
						'PPA Tariff must have no more than 3 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				percentageOnGridTariff: yup
					.number()
					.typeError(t('% on Grid Tariff must be a number'))
					.min(0, t('% on Grid Tariff must be greater than or equal to 0'))
					.test(
						'decimal-places',
						'% on Grid Tariff must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				floorTariff: yup
					.number()
					.typeError(t('Floor Tariff must be a number'))
					.positive('Floor Tariff must be positive')
					.test(
						'decimal-places',
						'Floor Tariff must have no more than 3 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				ceilingTariff: yup
					.number()
					.typeError(t('Ceiling Tariff must be a number'))
					.positive('Ceiling Tariff must be positive')
					.test(
						'decimal-places',
						'Ceiling Tariff must have no more than 3 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				expectedClientSavings: yup
					.number()
					.typeError(t('Expected Client Savings must be a number'))
					.positive('Expected Client Savings must be positive')
					.test(
						'decimal-places',
						'Expected Client Savings must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				solarEnergyReplacement: yup
					.number()
					.typeError(t('Solar Energy Replacement for Client must be a number'))
					.positive('Solar Energy Replacement for Client must be positive')
					.test(
						'decimal-places',
						'Solar Energy Replacement for Client must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				irr: yup
					.number()
					.typeError(t('Projected IRR must be a number'))
					.positive(t('Projected IRR must be positive'))
					.max(100, t('Projected IRR must be less than or equal to 100'))
					.test(
						'decimal-places',
						'Projected IRR must have no more than 3 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				worstCaseIrr: yup
					.number()
					.typeError(t('Worst Case IRR must be a number'))
					.positive(t('Worst Case IRR must be positive'))
					.max(100, t('Worst Case IRR must be less than or equal to 100'))
					.test(
						'decimal-places',
						'Worst Case IRR must have no more than 3 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,3})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				minimumDscr: yup
					.number()
					.typeError(t('Minimum DSCR must be a number'))
					.positive(t('Minimum DSCR must be positive'))
					.test(
						'decimal-places',
						'Minimum DSCR must have no more than 2 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				averageDscr: yup
					.number()
					.typeError(t('Average DSCR must be a number'))
					.positive(t('Average DSCR must be positive'))
					.test(
						'decimal-places',
						'Average DSCR must have no more than 2 decimal places',
						value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
			}),
		[t],
	);
};

export default useClientOfferOverlayValidationSchema;
