import { Grid } from '@mui/material';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';
import CurrencySign from 'Common/components/CurrencySign';
import FormField from 'Common/components/form/FormField';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import crudModes from 'Common/constants/crudModes';
import UNITS from 'Common/constants/units';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import loadEpcPartnerOptions from 'EpcPartner/utils/loadEpcPartnerOptions';
import { mountingTypeOptions } from 'Projects/constants/mountingTypeOptions';
import { PaymentGuaranteeOptions, PaymentGuaranteeTypes } from 'Projects/constants/paymentGuaranteeOptions';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FileUploadField from 'Common/components/form/FileUploadField';
import { FILE_TYPES } from 'Common/constants/fileTypes';
import getClientContractDownloadLink from '../../../../ClientContract/api/getClientContractDownloadLink';
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';

const ProjectDetailsSection = ({
	mode,
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldValue,
	setFieldTouched,
	breadcrumbList,
}) => {
	const { t } = useTranslation();
	const isViewMode = mode === crudModes.VIEW;
	const abortController = useAbortController();

	const mountingTypesValue = useMemo(
		() => values?.mountingTypes?.map(mountingType => mountingType.label).join(', '),
		[values?.mountingTypes],
	);

	const handleDocumentDownload = async document => {
		try {
			const response = await getClientContractDownloadLink(
				abortController.signal,
				document.name,
				document.versionId,
			);
			window.open(response.data, '_blank');
		} catch (error) {
			showToastError(error);
		}
	};

	return !isViewMode ? (
		<SectionWrapper $reduceBottomPadding>
			<Grid container>
				<Grid item xs={12} md={6}>
					<FormField data-first-field>
						<FormikInput
							id="systemSize"
							name="systemSize"
							label={t('SaaS system size')}
							value={values.systemSize}
							error={errors.systemSize}
							touched={touched.systemSize}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							unit={UNITS.KWP}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							isMulti
							id="mountingTypes"
							name="mountingTypes"
							label={t('Mounting type')}
							value={values.mountingTypes}
							error={errors.mountingTypes}
							touched={touched.mountingTypes}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							options={mountingTypeOptions}
							isRequired
							isClearable
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="epcPartner"
							name="epcPartner"
							label={t('EPC partner')}
							value={values.epcPartner}
							error={errors.epcPartner}
							touched={touched.epcPartner}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							loadOptions={loadEpcPartnerOptions}
							isRequired
							isClearable
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							prefix={() => <CurrencySign currency={values.currency} />}
							id="epcVolume"
							name="epcVolume"
							label={t('EPC volume')}
							value={values.epcVolume}
							error={errors.epcVolume}
							touched={touched.epcVolume}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="currency"
							name="currency"
							label={t('Currency')}
							value={values.currency}
							error={errors.currency}
							touched={touched.currency}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCurrenciesOptions}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="yield"
							name="yield"
							label={t('Yield')}
							value={values.yield}
							error={errors.yield}
							touched={touched.yield}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
							unit={UNITS.KWH_KWP}
						/>
					</FormField>
					<FormField>
						<FileUploadField
							label={t('Document')}
							value={values.document}
							name="document"
							fileName="Document"
							displayValue={values.documentDisplayName}
							acceptedTypes={[FILE_TYPES.DOCX, FILE_TYPES.PDF, FILE_TYPES.ODT]}
							documents={values.clientContractDocuments}
							onDocumentDownload={handleDocumentDownload}
							breadcrumbList={breadcrumbList}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
						/>
					</FormField>
					<FormField>
						<FormikCheckbox
							label={t('P90')}
							id="p90"
							name="p90"
							checked={values.p90}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.p90}
						/>
					</FormField>
				</Grid>

				<Grid item xs={12} md={6}>
					<FormField>
						<FormikInput
							id="solarPvDegradation"
							name="solarPvDegradation"
							label={t('SaaS degradation rate')}
							value={values.solarPvDegradation}
							error={errors.solarPvDegradation}
							touched={touched.solarPvDegradation}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							unit={UNITS.PERCENT}
							id="solarUtilizationRate"
							name="solarUtilizationRate"
							label={t('Solar utilization rate')}
							value={values.solarUtilizationRate}
							error={errors.solarUtilizationRate}
							touched={touched.solarUtilizationRate}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="saasOutageFactor"
							name="saasOutageFactor"
							label={t('SaaS outage factor')}
							value={values.saasOutageFactor}
							error={errors.saasOutageFactor}
							touched={touched.saasOutageFactor}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							prefix={() => <CurrencySign currency={values.currency} />}
							id="annualOmPrice"
							name="annualOmPrice"
							label={t('Annual O&M price')}
							value={values.annualOmPrice}
							error={errors.annualOmPrice}
							touched={touched.annualOmPrice}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="annualOmEscalationRate"
							name="annualOmEscalationRate"
							label={t('Annual O&M escalation rate')}
							value={values.annualOmEscalationRate}
							error={errors.annualOmEscalationRate}
							touched={touched.annualOmEscalationRate}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="saasOutageFactor"
							name="saasOutageFactor"
							label={t('SaaS outage factor')}
							value={values.saasOutageFactor}
							error={errors.saasOutageFactor}
							touched={touched.saasOutageFactor}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="extraCapexExpected"
							name="extraCapexExpected"
							label={t('Extra CAPEX expected')}
							value={values.extraCapexExpected}
							error={errors.extraCapexExpected}
							touched={touched.extraCapexExpected}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="extraOpexExpected"
							name="extraOpexExpected"
							label={t('Extra OPEX expected')}
							value={values.extraOpexExpected}
							error={errors.extraOpexExpected}
							touched={touched.extraOpexExpected}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							id="paymentGuaranteeType"
							name="paymentGuaranteeType"
							label={t('Payment Guarantee Type')}
							value={values.paymentGuaranteeType}
							error={errors.paymentGuaranteeType}
							touched={touched.paymentGuaranteeType}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							options={PaymentGuaranteeOptions}
							isClearable
							isRequired
							isOverlay
						/>
					</FormField>
					<ConditionalGridSection hasFlag={values.paymentGuaranteeType?.value === PaymentGuaranteeTypes.OTHER}>
						<FormField>
							<FormikTextArea
								id="paymentGuaranteeDetails"
								name="paymentGuaranteeDetails"
								label={t('Payment Guarantee Details')}
								value={values.paymentGuaranteeDetails}
								error={errors.paymentGuaranteeDetails}
								touched={touched.paymentGuaranteeDetails}
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection>
					<FormField data-last-field>
						<FormikInput
							id="paymentGuaranteeAmount"
							name="paymentGuaranteeAmount"
							prefix={() => <CurrencySign currency={values.currency} />}
							label={t('Payment Guarantee Amount')}
							value={values.paymentGuaranteeAmount}
							error={errors.paymentGuaranteeAmount}
							touched={touched.paymentGuaranteeAmount}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
				</Grid>
			</Grid>
		</SectionWrapper>
	) : (
		<SectionWrapper $reduceBottomPadding>
			<Grid container>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization id="systemSize" title={t('System size')} value={values.systemSize} />
					<KeyValueVisualization id="mountingTypes" title={t('Mounting type')} value={mountingTypesValue} />
					<KeyValueVisualization id="epcPartner" title={t('EPC partner')} value={values?.epcPartner?.label} />
					<KeyValueVisualization
						id="epcVolume"
						prefix={() => <CurrencySign currency={values.currency} />}
						title={t('EPC volume')}
						value={values.epcVolume}
					/>

					<KeyValueVisualization id="currency" title={t('Currency')} value={values?.currency?.label} />
					<KeyValueVisualization id="yield" title={t('Yield')} value={values.yield} unit={UNITS.KWH_KWP} />
					<KeyValueVisualization id="p90" title={t('P90')} value={values.p90 ? 'Yes' : 'No'} />
				</Grid>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization
						id="solarPvDegradation"
						title={t('SaaS degradation rate')}
						value={values.solarPvDegradation}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization
						id="solarUtilizationRate"
						title={t('Solar utilization rate')}
						value={values.solarUtilizationRate}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization id="annualOmPrice" title={t('Annual O&M price')} value={values.annualOmPrice} />
					<KeyValueVisualization
						id="annualOmEscalationRate"
						title={t('Annual O&M escalation rate')}
						value={values.annualOmEscalationRate}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization
						id="saasOutageFactor"
						title={t('SaaS outage factor')}
						value={values.saasOutageFactor}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization
						id="extraCapexExpected"
						title={t('Extra CAPEX expected')}
						value={values.extraCapexExpected}
					/>
					<KeyValueVisualization
						id="extraOpexExpected"
						title={t('Extra OPEX expected')}
						value={values.extraOpexExpected}
					/>
					<KeyValueVisualization
						id="paymentGuaranteeType"
						title={t('Payment Guarantee Type')}
						value={values.paymentGuaranteeType?.label}
					/>
					{values.paymentGuaranteeType?.value === PaymentGuaranteeTypes.OTHER && (
						<KeyValueVisualization
							id="paymentGuaranteeDetails"
							title={t('Payment Guarantee Details')}
							value={values.paymentGuaranteeDetails}
						/>
					)}
					<KeyValueVisualization
						id="paymentGuaranteeAmount"
						prefix={() => <CurrencySign currency={values.currency} />}
						title={t('Payment Guarantee Amount')}
						value={values.paymentGuaranteeAmount}
					/>
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

ProjectDetailsSection.defaultProps = {
	mode: crudModes.CREATE,
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
	breadcrumbList: [],
};

ProjectDetailsSection.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT, crudModes.VIEW]),
	values: PropTypes.shape({
		epcPartner: PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		epcVolume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		currency: PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
		yield: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		document: PropTypes.shape({
			name: PropTypes.string,
		}),
		documentDisplayName: PropTypes.string,
		clientContractDocuments: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
			}),
		),
		p90: PropTypes.bool,
		solarPvDegradation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		solarUtilizationRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		annualOmPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		annualOmEscalationRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		saasOutageFactor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		extraCapexExpected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		extraOpexExpected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		systemSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		mountingTypes: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string,
				value: PropTypes.string,
			}),
		),
		paymentGuaranteeAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		paymentGuaranteeDetails: PropTypes.string,
		paymentGuaranteeType: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
	}),
	errors: PropTypes.shape({
		epcPartner: PropTypes.string,
		epcVolume: PropTypes.string,
		currency: PropTypes.string,
		yield: PropTypes.string,
		p90: PropTypes.string,
		solarPvDegradation: PropTypes.string,
		solarUtilizationRate: PropTypes.string,
		annualOmPrice: PropTypes.string,
		annualOmEscalationRate: PropTypes.string,
		saasOutageFactor: PropTypes.string,
		extraCapexExpected: PropTypes.string,
		extraOpexExpected: PropTypes.string,
		systemSize: PropTypes.string,
		mountingTypes: PropTypes.string,
		paymentGuaranteeAmount: PropTypes.string,
		paymentGuaranteeDetails: PropTypes.string,
		paymentGuaranteeType: PropTypes.string,
	}),
	touched: PropTypes.shape({
		epcPartner: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		epcVolume: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		currency: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		yield: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		p90: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		solarPvDegradation: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		solarUtilizationRate: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		annualOmPrice: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		annualOmEscalationRate: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		saasOutageFactor: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		extraCapexExpected: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		extraOpexExpected: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		systemSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		mountingTypes: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		paymentGuaranteeAmount: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		paymentGuaranteeDetails: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		paymentGuaranteeType: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string.isRequired, href: PropTypes.string }),
	),
};

export default ProjectDetailsSection;
