export const projectDeliverySource = {
	EPC_TURNKEY: 'EPC turnkey',
	ASSET_PURCHASE: 'Asset purchase',
};

export const projectDeliverySourceOptions = [
	{
		label: 'EPC turnkey',
		value: projectDeliverySource.EPC_TURNKEY,
	},
	{
		label: 'Asset purchase',
		value: projectDeliverySource.ASSET_PURCHASE,
	},
];
