const PM_WORKFLOW_TILES_KEYS = {
	// ? Client
	PROJECT_CLIENT: 'PROJECT_CLIENT',
	CLIENT_DETAILS: 'CLIENT_DETAILS',
	CLIENT_ADDRESS: 'CLIENT_ADDRESS',
	CLIENT_DUE_DILIGENCE: 'CLIENT_DUE_DILIGENCE',
	// ? Project
	PROJECT_DETAILS: 'PROJECT_DETAILS',
	PROJECT_ENGAGEMENT: 'PROJECT_ENGAGEMENT',
	PROJECT_RESPONSIBLE_PEOPLE: 'PROJECT_RESPONSIBLE_PEOPLE',
	PROJECT_STATUS: 'PROJECT_STATUS',
	PROJECT_LOCATION: 'PROJECT_LOCATION',
	PROJECT_ENTITIES: 'PROJECT_ENTITIES',
	PROJECT_EPC_PARTNER: 'PROJECT_EPC_PARTNER',
	// ? Offers
	PROJECT_RFEP: 'PROJECT_RFEP',
	// ? EPC
	EPC_PARTNER_PROJECT: 'EPC_PARTNER',
	EPC_PARTNER_DETAILS: 'EPC_PARTNER_DETAILS',
	EPC_PARTNER_ADDRESS: 'EPC_PARTNER_ADDRESS',
	EPC_PARTNER_QUALIFICATIONS: 'EPC_PARTNER_QUALIFICATIONS',
	EPC_PARTNER_ACCOUNT_MANAGERS: 'EPC_PARTNER_ACCOUNT_MANAGERS',
	// ? Operations
	PROJECT_INSURANCE: 'PROJECT_INSURANCE',
	PROJECT_COMMISSIONING: 'PROJECT_COMMISSIONING',
	// ? Meteocontrol
	PROJECT_METEOCONTROL: 'PROJECT_METEOCONTROL',
};

const PM_WORKFLOW_TILES_LABELS = {
	PROJECT_CLIENT: 'Project Client',
	CLIENT_DETAILS: 'Client Details',
	CLIENT_ADDRESS: 'Client Address',
	CLIENT_DUE_DILIGENCE: 'Client Due Diligence',
	PROJECT_DETAILS: 'Project Details',
	PROJECT_ENGAGEMENT: 'Project Engagement',
	PROJECT_RESPONSIBLE_PEOPLE: 'Project Responsible People',
	PROJECT_STATUS: 'Project Status',
	PROJECT_LOCATION: 'Project Location',
	PROJECT_ENTITIES: 'Project Entities',
	PROJECT_RFEP: 'Project RFEP',
	PROJECT_EPC_PARTNER: 'Project EPC Partner',
	EPC_PARTNER_DETAILS: 'EPC Partner Details',
	EPC_PARTNER_ADDRESS: 'EPC Partner Address',
	EPC_PARTNER_QUALIFICATIONS: 'EPC Partner Qualifications',
	EPC_PARTNER_ACCOUNT_MANAGERS: 'EPC Partner Account Managers',
	PROJECT_INSURANCE: 'Project Insurance',
	PROJECT_COMMISSIONING: 'Project Commissioning',
	PROJECT_METEOCONTROL: 'Project Meteocontrol',
};

const PM_WORKFLOW_TILES_OPTIONS = [
	{
		value: 'PROJECT_CLIENT',
		label: 'Project Client',
	},
	{
		value: 'CLIENT_DETAILS',
		label: 'Client Details',
	},
	{
		value: 'CLIENT_ADDRESS',
		label: 'Client Address',
	},
	{
		value: 'CLIENT_DUE_DILIGENCE',
		label: 'Project Due Diligence',
	},
	{
		value: 'PROJECT_DETAILS',
		label: 'Project Details',
	},
	{
		value: 'PROJECT_ENGAGEMENT',
		label: 'Project Engagement',
	},
	{
		value: 'PROJECT_RESPONSIBLE_PEOPLE',
		label: 'Project Responsible People',
	},
	{
		value: 'PROJECT_STATUS',
		label: 'Project Status',
	},
	{
		value: 'PROJECT_LOCATION',
		label: 'Project Location',
	},
	{
		value: 'PROJECT_ENTITIES',
		label: 'Project Entities',
	},
	{
		value: 'PROJECT_RFEP',
		label: 'Project RFEP',
	},
	{
		value: 'PROJECT_EPC_PARTNER',
		label: 'Project EPC Partner',
	},
	{
		value: 'EPC_PARTNER_DETAILS',
		label: 'EPC Partner Details',
	},
	{
		value: 'EPC_PARTNER_ADDRESS',
		label: 'EPC Partner Address',
	},
	{
		value: 'EPC_PARTNER_QUALIFICATIONS',
		label: 'EPC Partner Qualifications',
	},
	{
		value: 'EPC_PARTNER_ACCOUNT_MANAGERS',
		label: 'EPC Partner Account Managers',
	},
	{
		value: 'PROJECT_INSURANCE',
		label: 'Project Insurance',
	},
	{
		value: 'PROJECT_COMMISSIONING',
		label: 'Project Commissioning',
	},
	{
		value: 'PROJECT_METEOCONTROL',
		label: 'Project Meteocontrol',
	},
];

const PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS = {
	// ? Client
	PROJECT_CLIENT: {
		CLIENT: 'CLIENT',
		UNKNOWN_CLIENT: 'UNKNOWN_CLIENT',
	},
	CLIENT_DETAILS: {
		CLIENT_NAME: 'CLIENT_NAME',
		REGISTRATION_NUMBER: 'REGISTRATION_NUMBER',
		PIN: 'PIN',
		CLIENT_WEBSITE: 'CLIENT_WEBSITE',
		INDUSTRY: 'INDUSTRY',
		CLIENT_FOUNDATION_YEAR: 'CLIENT_FOUNDATION_YEAR',
		EMPLOYEES: 'EMPLOYEES',
		FEMALE_EMPLOYEES: 'FEMALE_EMPLOYEES',
		MANAGERS: 'MANAGERS',
		FEMALE_MANAGERS: 'FEMALE_MANAGERS',
		SUBJECT_TO_VAT: 'SUBJECT_TO_VAT',
	},
	CLIENT_ADDRESS: {
		CLIENT_COUNTRY: 'CLIENT_COUNTRY',
		CLIENT_APARTMENT_BUILDING: 'CLIENT_APARTMENT_BUILDING',
		CLIENT_ADDRESS_LINE_1: 'CLIENT_ADDRESS_LINE_1',
		CLIENT_ADDRESS_LINE_2: 'CLIENT_ADDRESS_LINE_2',
		CLIENT_CITY: 'CLIENT_CITY',
		CLIENT_POSTAL_CODE: 'CLIENT_POSTAL_CODE',
	},
	CLIENT_DUE_DILIGENCE: {
		ANNUAL_REPORT: 'ANNUAL_REPORT',
		FDD_PASSED: 'FDD_PASSED',
		FINANCIAL_PASSED_DATE: 'FINANCIAL_PASSED_DATE',
		PROFITABILITY_SCORING: 'PROFITABILITY_SCORING',
		LIQUIDITY_SCORING: 'LIQUIDITY_SCORING',
		SOLVENCY_SCORING: 'SOLVENCY_SCORING',
		SOLVENCY_SCORING_LOANS: 'SOLVENCY_SCORING_LOANS',
	},

	// ? Project
	PROJECT_DETAILS: {
		PROJECT_TYPE: 'PROJECT_TYPE',
		ENERGY_STORAGE: 'ENERGY_STORAGE',
		GENSETS: 'GENSETS',
		METER_QUANTITY: 'METER_QUANTITY',
		CONNECTION_POINTS: 'CONNECTION_POINTS',
		SYSTEM_SIZE_KWP: 'SYSTEM_SIZE_KWP',
		KWH_TO_KWP_REL: 'KWH_TO_KWP_REL',
		PREDICTED_ANNUAL_ENERGY_SAVINGS: 'PREDICTED_ANNUAL_ENERGY_SAVINGS',
		PREDICTED_ANNUAL_ENERGY_SAVINGS_KWH: 'PREDICTED_ANNUAL_ENERGY_SAVINGS_KWH',
		PREDICTED_ANNUAL_ENERGY_SAVINGS_KW: 'PREDICTED_ANNUAL_ENERGY_SAVINGS_KW',
		PV_DEGRADATION_RATE: 'PV_DEGRADATION_RATE',
		SOLAR_UTILIZATION_RATE: 'SOLAR_UTILIZATION_RATE',
		GRID_ENERGY_TARIFF_PER_KWH: 'GRID_ENERGY_TARIFF_PER_KWH',
		CURRENCY: 'CURRENCY',
		MOUNTING_TYPES: 'MOUNTING_TYPES',
		PROJECT_DELIVERY_SOURCE: 'PROJECT_DELIVERY_SOURCE',
		AVAILABLE_SPACE: 'AVAILABLE_SPACE',
		ROOF_TYPE: 'ROOF_TYPE',
		BATTERY_SIZE_IN_KW: 'BATTERY_SIZE_IN_KW',
		BATTERY_CAPACITY_IN_KWH: 'BATTERY_CAPACITY_IN_KWH',
		GENERATOR_SIZE: 'GENERATOR_SIZE',
		GENERATOR_QUANTITY: 'GENERATOR_QUANTITY',
		SOLAR_MODULES: 'SOLAR_MODULES',
		INVERTERS: 'INVERTERS',
		MOUNTING: 'MOUNTING',
		BATTERIES: 'BATTERIES',
		LED_LUMINAIRES: 'LED_LUMINAIRES',
		AC_UNITS: 'AC_UNITS',
		SOLAR_HOT_WATER: 'SOLAR_HOT_WATER',
		PROJECT_DESCRIPTION: 'PROJECT_DESCRIPTION',
	},
	PROJECT_ENGAGEMENT: {
		INITIAL_ENGAGEMENT_DATE: 'INITIAL_ENGAGEMENT_DATE',
		SALES_CHANNEL: 'SALES_CHANNEL',
	},
	PROJECT_RESPONSIBLE_PEOPLE: {
		SALES: 'SALES',
		TECH: 'TECH',
		FUNDRAISING: 'FUNDRAISING',
		MARKETING: 'MARKETING',
		ASSET_MANAGER: 'ASSET_MANAGER',
		FINANCE: 'FINANCE',
	},
	PROJECT_STATUS: {
		STATUS: 'STATUS',
		CLOSED_REASON: 'CLOSED_REASON',
		PRIORITY: 'PRIORITY',
	},
	PROJECT_LOCATION: {
		PROJECT_COUNTRY: 'PROJECT_COUNTRY',
		GRID_EMISSION_FACTOR: 'GRID_EMISSION_FACTOR',
		LOCATION_URL: 'LOCATION_URL',
		LOCATION_TEXT: 'LOCATION_TEXT',
	},
	PROJECT_ENTITIES: {
		EPC: 'EPC',
		SAAS: 'SAAS',
		TRANSMITTED_LOAD: 'TRANSMITTED_LOAD',
	},

	// ? Offers
	RFEP: {},

	// ? EPC
	PROJECT_EPC_PARTNER: {
		EPC_PARTNER: 'EPC_PARTNER',
	},
	EPC_PARTNER_DETAILS: {
		EPC_PARTNER_NAME: 'EPC_PARTNER_NAME',
		EPC_PARTNER_WEBSITE: 'EPC_PARTNER_WEBSITE',
	},
	EPC_PARTNER_ADDRESS: {
		EPC_PARTNER_COUNTRY: 'EPC_PARTNER_COUNTRY',
		EPC_PARTNER_APARTMENT_BUILDING: 'EPC_PARTNER_APARTMENT_BUILDING',
		EPC_PARTNER_ADDRESS_LINE_1: 'EPC_PARTNER_ADDRESS_LINE_1',
		EPC_PARTNER_ADDRESS_LINE_2: 'EPC_PARTNER_ADDRESS_LINE_2',
		EPC_PARTNER_CITY: 'EPC_PARTNER_CITY',
		EPC_PARTNER_POSTAL_CODE: 'EPC_PARTNER_POSTAL_CODE',
	},
	EPC_PARTNER_QUALIFICATIONS: {
		INTERNAL_QUALIFICATION_PASSED: 'INTERNAL_QUALIFICATION_PASSED',
		INTERNAL_PASS_DATE: 'INTERNAL_PASS_DATE',
		EXTERNAL_QUALIFICATION_PASSED: 'EXTERNAL_QUALIFICATION_PASSED',
		EXTERNAL_PASS_DATE: 'EXTERNAL_PASS_DATE',
	},
	EPC_PARTNER_ACCOUNT_MANAGERS: {
		PRIMARY_MANAGER: 'PRIMARY_MANAGER',
		SECONDARY_MANAGER: 'SECONDARY_MANAGER',
	},

	// ? Operations
	PROJECT_INSURANCE: {
		INSURANCE: 'INSURANCE',
	},

	PROJECT_POST_COMPLETION: {
		POST_COMPLETION: 'POST_COMPLETION',
	},
	PROJECT_COMMISSIONING: {
		COMMERCIAL_OPERATIONS_DATE: 'COMMERCIAL_OPERATIONS_DATE',
		COMMISSIONING_ACCEPTANCE_DATE: 'COMMISSIONING_ACCEPTANCE_DATE',
		UNIT_SYSTEM_SIZE: 'UNIT_SYSTEM_SIZE',
		PROJECTED_COMMERCIAL_OPERATIONS_DATE: 'PROJECTED_COMMERCIAL_OPERATIONS_DATE',
	},

	PROJECT_REPORTING: {
		ENERG_PRODUCTION_EXPLANATION: 'ENERG_PRODUCTION_EXPLANATION',
		COST_EXCEEDED: 'COST_EXCEEDED',
		M62_CHALLENGES: 'M62_CHALLENGES',
		SUCCESSES: 'SUCCESSES',
		M63_EXTRAORDINARY_EVENTS: 'M63_EXTRAORDINARY_EVENTS',
	},

	// ? Meteocontrol
	PROJECT_METEOCONTROL: {
		VCOM: 'VCOM',
	},
};

const PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_LABELS = {
	// ? Client
	CLIENT: 'Client',
	UNKNOWN_CLIENT: 'Unknown client',
	CLIENT_NAME: 'Client name',
	REGISTRATION_NUMBER: 'Registration number',
	PIN: 'PIN',
	CLIENT_WEBSITE: 'Client Website',
	INDUSTRY: 'Industry',
	CLIENT_FOUNDATION_YEAR: 'Client foundation year',
	EMPLOYEES: 'Employees',
	FEMALE_EMPLOYEES: 'Female Employees',
	MANAGERS: 'Managers',
	FEMALE_MANAGERS: 'Female Managers',
	SUBJECT_TO_VAT: 'Subject to VAT',
	CLIENT_COUNTRY: 'Client Country',
	CLIENT_APARTMENT_BUILDING: 'Client Apartment / building',
	CLIENT_ADDRESS_LINE_1: 'Client Address Line 1',
	CLIENT_ADDRESS_LINE_2: 'Client Address Line 2',
	CLIENT_CITY: 'Client City',
	CLIENT_POSTAL_CODE: 'Client Postal code',
	ANNUAL_REPORT: 'Annual report',
	FINANCIAL_PASSED_DATE: 'Financial DD passed date',
	PROFITABILITY_SCORING: 'Profitability scoring',
	LIQUIDITY_SCORING: 'Liquidity scoring',
	SOLVENCY_SCORING: 'Solvency scoring',
	SOLVENCY_SCORING_LOANS: 'Solvency scoring loans',
	// ? Project
	PROJECT_TYPE: 'Project type',
	ENERGY_STORAGE: 'Energy storage',
	GENSETS: 'Gensets',
	METER_QUANTITY: 'Meter quantity',
	CONNECTION_POINTS: 'Connection points',
	SYSTEM_SIZE_KWP: 'System size (kWp)',
	KWH_TO_KWP_REL: 'Yield',
	PREDICTED_ANNUAL_ENERGY_SAVINGS: 'Predicted annual energy savings',
	PREDICTED_ANNUAL_ENERGY_SAVINGS_KWH: 'Predicted annual energy savings (kWh)',
	PREDICTED_ANNUAL_ENERGY_SAVINGS_KW: 'Predicted annual energy savings (kW)',
	PV_DEGRADATION_RATE: 'PV degradation rate',
	SOLAR_UTILIZATION_RATE: 'Solar utilization rate',
	GRID_ENERGY_TARIFF_PER_KWH: 'Grid energy tariff per kWh',
	CURRENCY: 'Currency',
	MOUNTING_TYPES: 'Mounting types',
	AVAILABLE_SPACE: 'Available space',
	ROOF_TYPE: 'Roof type',
	BATTERY_SIZE_IN_KW: 'Battery size in kW',
	BATTERY_CAPACITY_IN_KWH: 'Battery capacity in kWh',
	GENERATOR_SIZE: 'Generator size',
	GENERATOR_QUANTITY: 'Generator quantity',
	SOLAR_MODULES: 'Modules',
	INVERTERS: 'Inverters',
	MOUNTING: 'Mounting',
	BATTERIES: 'Batteries',
	LED_LUMINAIRES: 'LED luminaires',
	AC_UNITS: 'AC units',
	SOLAR_HOT_WATER: 'Solar hot water',
	INITIAL_ENGAGEMENT_DATE: 'Initial engagement date',
	SALES_CHANNEL: 'Sales channel',
	SALES: 'Sales',
	TECH: 'Tech',
	FUNDRAISING: 'Fundraising',
	MARKETING: 'Marketing',
	ASSET_MANAGER: 'Asset manager',
	STATUS: 'Status',
	CLOSED_REASON: 'Closed reason',
	PRIORITY: 'Priority',
	PROJECT_COUNTRY: 'Project Country',
	GRID_EMISSION_FACTOR: 'Grid emission factor',
	LOCATION_URL: 'Location URL',
	LOCATION_TEXT: 'Location text',

	EPC: 'EPC',
	SAAS: 'SaaS',
	TRANSMITTED_LOAD: 'Transmitted load',

	// ? Offers

	// ? EPC
	EPC_PARTNER: 'EPC partner',
	EPC_PARTNER_NAME: 'Name',
	EPC_PARTNER_WEBSITE: 'EPC Partner Website',
	EPC_PARTNER_COUNTRY: 'EPC Partner Country',
	EPC_PARTNER_APARTMENT_BUILDING: 'EPC Partner Apartment / building',
	EPC_PARTNER_ADDRESS_LINE_1: 'EPC Partner Address Line 1',
	EPC_PARTNER_ADDRESS_LINE_2: 'EPC Partner Address Line 2',
	EPC_PARTNER_CITY: 'EPC Partner City',
	EPC_PARTNER_POSTAL_CODE: 'EPC Partner Postal code',
	INTERNAL_QUALIFICATION_PASSED: 'Internal qualification passed',
	INTERNAL_PASS_DATE: 'Internal pass date',
	EXTERNAL_QUALIFICATION_PASSED: 'External qualification passed',
	EXTERNAL_PASS_DATE: 'External pass date',
	PRIMARY_MANAGER: 'Primary manager',
	SECONDARY_MANAGER: 'Secondary manager',
	// ? Operations
	INSURANCE: 'Insurance',
	COMMERCIAL_OPERATIONS_DATE: 'Commercial operations date',
	COMMISSIONING_ACCEPTANCE_DATE: 'Commissioning acceptance date',

	// ? Meteocontrol
	VCOM: 'Vcom Name',
};

const PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_OPTIONS = {
	// ? Client
	PROJECT_CLIENT: [
		{
			value: 'CLIENT',
			label: 'Client',
		},
		{
			value: 'UNKNOWN_CLIENT',
			label: 'Unknown client',
		},
	],
	CLIENT_DETAILS: [
		{
			value: 'CLIENT_NAME',
			label: 'Client Name',
		},
		{
			value: 'REGISTRATION_NUMBER',
			label: 'Registration Number',
		},
		{
			value: 'PIN',
			label: 'PIN',
		},
		{
			value: 'CLIENT_WEBSITE',
			label: 'Website',
		},
		{
			value: 'INDUSTRY',
			label: 'Industry',
		},
		{
			value: 'CLIENT_FOUNDATION_YEAR',
			label: 'Client Foundation Year',
		},
		{
			value: 'EMPLOYEES',
			label: 'Employees',
		},
		{
			value: 'FEMALE_EMPLOYEES',
			label: 'Female Employees',
		},
		{
			value: 'MANAGERS',
			label: 'Managers',
		},
		{
			value: 'FEMALE_MANAGERS',
			label: 'Female Managers',
		},
		{
			value: 'SUBJECT_TO_VAT',
			label: 'Subject To VAT WHT',
		},
	],
	CLIENT_ADDRESS: [
		{
			value: 'CLIENT_COUNTRY',
			label: 'Client Country',
		},
		{
			value: 'CLIENT_APARTMENT_BUILDING',
			label: 'Client Apartment / Building',
		},
		{
			value: 'CLIENT_ADDRESS_LINE_1',
			label: 'Client Address Line 1',
		},
		{
			value: 'CLIENT_ADDRESS_LINE_2',
			label: 'Client Address Line 2',
		},
		{
			value: 'CLIENT_CITY',
			label: 'Client City / Region',
		},
		{
			value: 'CLIENT_POSTAL_CODE',
			label: 'Client Postal Code / ZIP',
		},
	],
	CLIENT_DUE_DILIGENCE: [
		{
			value: 'ANNUAL_REPORT',
			label: 'Annual Report',
		},
		{
			value: 'FINANCIAL_PASSED_DATE',
			label: 'Financial DD Passed Date',
		},
		{
			value: 'PROFITABILITY_SCORING',
			label: 'Profitability Scoring',
		},
		{
			value: 'LIQUIDITY_SCORING',
			label: 'Liquidity Scoring',
		},
		{
			value: 'SOLVENCY_SCORING',
			label: 'Solvency Scoring',
		},
		{
			value: 'SOLVENCY_SCORING_LOANS',
			label: 'Solvency Scoring Loans',
		},
	],

	// ? Project
	PROJECT_DETAILS: [
		{
			value: 'PROJECT_TYPE',
			label: 'Project type',
		},
		{
			value: 'ENERGY_STORAGE',
			label: 'Energy storage',
		},
		{
			value: 'GENSETS',
			label: 'Gensets',
		},
		{
			value: 'METER_QUANTITY',
			label: 'Meter quantity',
		},
		{
			value: 'CONNECTION_POINTS',
			label: 'Connection points',
		},
		{
			value: 'SYSTEM_SIZE_KWP',
			label: 'System size',
		},
		{
			value: 'KWH_TO_KWP_REL',
			label: 'Yield',
		},
		{
			value: 'PREDICTED_ANNUAL_ENERGY_SAVINGS',
			label: 'Predicted annual energy savings',
		},
		{
			value: 'PREDICTED_ANNUAL_ENERGY_SAVINGS_KWH',
			label: 'Predicted annual energy savings (kWh)',
		},
		{
			value: 'PREDICTED_ANNUAL_ENERGY_SAVINGS_KW',
			label: 'Predicted annual energy savings (kW)',
		},
		{
			value: 'PV_DEGRADATION_RATE',
			label: 'PV degradation rate',
		},
		{
			value: 'SOLAR_UTILIZATION_RATE',
			label: 'Solar utilization rate',
		},
		{
			value: 'GRID_ENERGY_TARIFF_PER_KWH',
			label: 'Grid energy tariff per kWh',
		},
		{
			value: 'CURRENCY',
			label: 'Currency',
		},
		{
			value: 'MOUNTING_TYPES',
			label: 'Mounting types',
		},
		{
			value: 'AVAILABLE_SPACE',
			label: 'Available space',
		},
		{
			value: 'ROOF_TYPE',
			label: 'Roof type',
		},
		{
			value: 'BATTERY_SIZE_IN_KW',
			label: 'Battery inverter size',
		},
		{
			value: 'BATTERY_CAPACITY_IN_KWH',
			label: 'Battery capacity',
		},
		{
			value: 'GENERATOR_SIZE',
			label: 'Generator size',
		},
		{
			value: 'GENERATOR_QUANTITY',
			label: 'Generator quantity',
		},
		{
			value: 'SOLAR_MODULES',
			label: 'Modules',
		},
		{
			value: 'INVERTERS',
			label: 'Inverters',
		},
		{
			value: 'MOUNTING',
			label: 'Mounting',
		},
		{
			value: 'BATTERIES',
			label: 'Batteries',
		},
		{
			value: 'LED_LUMINAIRES',
			label: 'LED luminaires',
		},
		{
			value: 'AC_UNITS',
			label: 'AC units',
		},
		{
			value: 'SOLAR_HOT_WATER',
			label: 'Solar hot water',
		},
	],
	PROJECT_ENGAGEMENT: [
		{
			value: 'INITIAL_ENGAGEMENT_DATE',
			label: 'Initial Engagement Date',
		},
		{
			value: 'SALES_CHANNEL',
			label: 'Sales channel',
		},
	],
	PROJECT_RESPONSIBLE_PEOPLE: [
		{
			value: 'SALES',
			label: 'Sales',
		},
		{
			value: 'TECH',
			label: 'Tech',
		},
		{
			value: 'FUNDRAISING',
			label: 'Fundraising',
		},
		{
			value: 'MARKETING',
			label: 'Marketing',
		},
		{
			value: 'ASSET_MANAGER',
			label: 'Asset Manager',
		},
	],
	PROJECT_STATUS: [
		{
			value: 'STATUS',
			label: 'Status',
		},
		{
			value: 'CLOSED_REASON',
			label: 'Closed Reason',
		},
		{
			value: 'PRIORITY',
			label: 'Priority',
		},
	],
	PROJECT_LOCATION: [
		{
			value: 'PROJECT_COUNTRY',
			label: 'Project Country',
		},
		{
			value: 'GRID_EMISSION_FACTOR',
			label: 'Grid emission factor',
		},
		{
			value: 'LOCATION_URL',
			label: 'Google Maps Link',
		},
		{
			value: 'LOCATION_TEXT',
			label: 'Latitude and Longitude',
		},
	],
	PROJECT_ENTITIES: [
		{
			value: 'EPC',
			label: 'EPC contract party',
		},
		{
			value: 'SAAS',
			label: 'SaaS contract party',
		},
		{
			value: 'TRANSMITTED_LOAD',
			label: 'Transmitted loan contract party',
		},
	],

	// ? Offers
	RFEP: [],

	// ? EPC
	PROJECT_EPC_PARTNER: [
		{
			value: 'EPC_PARTNER',
			label: 'EPC Partner',
		},
	],
	EPC_PARTNER_DETAILS: [
		{
			value: 'EPC_PARTNER_NAME',
			label: 'Name',
		},
		{
			value: 'EPC_PARTNER_WEBSITE',
			label: 'Website',
		},
	],
	EPC_PARTNER_ADDRESS: [
		{
			value: 'EPC_PARTNER_COUNTRY',
			label: 'Country',
		},
		{
			value: 'EPC_PARTNER_APARTMENT_BUILDING',
			label: 'Apartment / Building',
		},
		{
			value: 'EPC_PARTNER_ADDRESS_LINE_1',
			label: 'Address Line 1',
		},
		{
			value: 'EPC_PARTNER_ADDRESS_LINE_2',
			label: 'Address Line 2',
		},
		{
			value: 'EPC_PARTNER_CITY',
			label: 'City / Region',
		},
		{
			value: 'EPC_PARTNER_POSTAL_CODE',
			label: 'Postal Code / ZIP',
		},
	],
	EPC_PARTNER_QUALIFICATIONS: [
		{
			value: 'INTERNAL_QUALIFICATION_PASSED',
			label: 'Internal qualification passed',
		},
		{
			value: 'INTERNAL_PASS_DATE',
			label: 'Internal pass date',
		},
		{
			value: 'EXTERNAL_QUALIFICATION_PASSED',
			label: 'External qualification passed',
		},
		{
			value: 'EXTERNAL_PASS_DATE',
			label: 'External pass date',
		},
	],
	EPC_PARTNER_ACCOUNT_MANAGERS: [
		{
			value: 'PRIMARY_MANAGER',
			label: 'Primary Manager',
		},
		{
			value: 'SECONDARY_MANAGER',
			label: 'Secondary Manager',
		},
	],

	// ? Operations
	PROJECT_INSURANCE: [
		{
			value: 'INSURANCE',
			label: 'Insurance',
		},
	],
	PROJECT_COMMISSIONING: [
		{
			value: 'COMMERCIAL_OPERATIONS_DATE',
			label: 'Commercial operations date (COD)',
		},
		{
			value: 'COMMISSIONING_ACCEPTANCE_DATE',
			label: 'Commissioning acceptance date',
		},
	],

	// ? Meteocontrol
	PROJECT_METEOCONTROL: [
		{
			value: 'VCOM',
			label: 'VCOM Name',
		},
	],
};

export {
	PM_WORKFLOW_TILES_KEYS,
	PM_WORKFLOW_TILES_LABELS,
	PM_WORKFLOW_TILES_OPTIONS,
	PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS,
	PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_LABELS,
	PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_OPTIONS,
};
