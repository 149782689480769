import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useProjectDefaultsFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				pvDegradationRate: yup
					.number()
					.nullable()
					.positive(t('Must be positive'))
					.max(100, t('Must be less than or equal to 100'))
					.typeError(t('Must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'decimal-places',
						t('Must have no more than 2 decimal places'),
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.required(t('Required')),
				euroPerKwp: yup
					.number()
					.typeError(t('Must be a number'))
					.nullable()
					.positive('Must be positive')
					.required(t('Required')),
				PV: yup
					.number()
					.typeError(t('Must be a number'))
					.integer('Must be an integer')
					.required(t('Required'))
					.max(99, t('Must be less than or equal to 99'))
					.positive('Must be positive'),
				'EE-AC': yup
					.number()
					.typeError(t('Must be a number'))
					.integer('Must be an integer')
					.required(t('Required'))
					.max(99, t('Must be less than or equal to 99'))
					.positive('Must be positive'),
				'EE-SHW': yup
					.number()
					.typeError(t('Must be a number'))
					.integer('Must be an integer')
					.required(t('Required'))
					.max(99, t('Must be less than or equal to 99'))
					.positive('Must be positive'),
				'EE-LED': yup
					.number()
					.typeError(t('Must be a number'))
					.integer('Must be an integer')
					.required(t('Required'))
					.max(99, t('Must be less than or equal to 99'))
					.positive('Must be positive'),
				allowedPercentageOfRestrictedIndustries: yup
					.number()
					.nullable()
					.positive(t('Must be positive'))
					.max(100, t('Must be less than or equal to 100'))
					.typeError(t('Must be a number')),
			}),
		[t],
	);
};

export default useProjectDefaultsFormValidationSchema;
