/* eslint-disable complexity */
import Big from 'big.js';
import crudModes from 'Common/constants/crudModes';
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import { parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

const fallbackValues = {
	systemSize: '',
	mountingTypes: [],
	epcPartner: null,
	epcVolume: '',
	currency: { label: 'EUR Euro', value: 'EUR' },
	yield: '',
	document: '',
	documentDisplayName: '',
	clientContractDocuments: [],
	p90: false,
	solarPvDegradation: '',
	annualOmPrice: '',
	annualOmEscalationRate: '',
	saasOutageFactor: '',
	extraCapexExpected: '',
	extraOpexExpected: '',
	paymentGuaranteeType: null,
	paymentGuaranteeAmount: '',
	paymentGuaranteeDetails: '',

	contractType: null,
	saasCurrency: { label: 'EUR Euro', value: 'EUR' },
	monthlyLeaseRate: '',
	ppaTariffType: null,
	ppaPricePerKwh: '',
	percentageOnGridTariff: '',
	floorTariff: '',
	ceilingTariff: '',
	escalationRateInPercent: '',
	contractDuration: '',
	clientUpfrontPayment: '',
	solarUtilizationRate: '',
	minimumPurchaseAmount: '',
	discountOnGridTariff: '',
	billingPeriod: '',

	contractParty: null,
	saasVersion: '',
	longstop: null,
	handoverPrice: '',
	bankAccount: null,
	easementFee: '',
	minimumWarrantedFunctionality: '',

	projectedIrr: '',
	worstCaseIrr: '',
	saasSignatureDate: null,
	contractNumber: '',
	minimumDscr: '',
	averageDscr: '',
};

const useClientContractOverlayValues = (mode, propData, primaryOffer, project) => {
	const [currencies, setCurrencies] = useState({
		default: fallbackValues.currency,
		saas: fallbackValues.saasCurrency,
	});

	const abortController = useAbortController();

	// ? Convert zeros to strings because JS sucks
	const data = useMemo(
		() =>
			propData
				? Object.entries(propData).reduce(
						(acc, [key, value]) => ({
							...acc,
							[key]:
								value === 0
									? '0'
									: typeof value === 'object' && value !== null && !Array.isArray(value)
									? {
											...value,
											value: value.value === 0 ? '0' : value.value,
									  }
									: value,
						}),
						{},
				  )
				: {},
		[propData],
	);

	// Set the default currency to EUR
	useEffect(() => {
		(async () => {
			try {
				if (data?.currency || data?.saasCurrency || primaryOffer?.currency || primaryOffer?.saasCurrency) {
					const currencies = await loadCurrenciesOptions(abortController.signal, false);
					const defaultCurrency = currencies.find(
						e =>
							e.value === (data?.currency || primaryOffer?.currency || 'EUR') ||
							e.label === (data?.currency || primaryOffer?.currency || 'EUR Euro'),
					);
					const saasCurrency = currencies.find(
						e =>
							e.value === (data?.saasCurrency || primaryOffer?.saasCurrency || 'EUR') ||
							e.label === (data?.saasCurrency || primaryOffer?.saasCurrency || 'EUR Euro'),
					);
					setCurrencies({
						default: defaultCurrency,
						saas: saasCurrency,
					});
				} else {
					const currencies = await loadCurrenciesOptions(abortController.signal, 'eur', false);
					const euro = currencies.find(e => e.value === 'EUR');
					setCurrencies({
						default: euro,
						saas: euro,
					});
				}
			} catch (err) {
				showToastError(err, "Can't fetch currencies");
			}
		})();
	}, [
		abortController.signal,
		data?.currency,
		data?.saasCurrency,
		primaryOffer?.currency,
		primaryOffer?.saasCurrency,
	]);

	const [document, setDocument] = useState(data?.documents?.slice(-1)[0]);

	useEffect(() => {
		setDocument(data?.documents?.slice(-1)[0]);
	}, [data?.documents]);

	const defaultValues = useMemo(
		() =>
			mode === crudModes.CREATE
				? {
						...fallbackValues,
						systemSize: primaryOffer?.systemSize || fallbackValues.systemSize,
						mountingTypes: primaryOffer?.mountingTypes?.length
							? primaryOffer.mountingTypes.map(({ mountingType, displayName }) => ({
									value: mountingType,
									label: displayName,
							  }))
							: fallbackValues.mountingTypes,
						epcPartner: primaryOffer?.epcPartner
							? { label: primaryOffer?.epcPartner.name, value: primaryOffer?.epcPartner.id }
							: fallbackValues.epcPartner,
						epcVolume: primaryOffer?.epcVolume?.value || fallbackValues.epcVolume,
						currency: currencies.default,
						yield: primaryOffer?.yield || fallbackValues.yield,
						document: '',
						documentDisplayName: '',
						clientContractDocuments: [],
						p90: primaryOffer?.p90 || fallbackValues.p90,
						solarPvDegradation: primaryOffer?.solarPvDegradation
							? Big(primaryOffer.solarPvDegradation).times(100).toString()
							: fallbackValues.solarPvDegradation,
						annualOmPrice: primaryOffer?.annualOmPrice?.value || fallbackValues.annualOmPrice,
						annualOmEscalationRate: primaryOffer?.annualOmEscalationRate
							? Big(primaryOffer.annualOmEscalationRate).times(100).toString()
							: fallbackValues.annualOmEscalationRate,
						extraCapexExpected: primaryOffer?.extraCapexExpected || fallbackValues.extraCapexExpected,
						extraOpexExpected: primaryOffer?.extraOpexExpected || fallbackValues.extraOpexExpected,
						paymentGuaranteeType: fallbackValues.paymentGuaranteeType,
						paymentGuaranteeAmount: fallbackValues.paymentGuaranteeAmount,
						paymentGuaranteeDetails: fallbackValues.paymentGuaranteeDetails,

						contractType: primaryOffer?.contractType
							? { label: primaryOffer.contractType.name, value: Number(primaryOffer.contractType.id) }
							: fallbackValues.contractType,
						saasCurrency: currencies.saas,
						monthlyLeaseRate: primaryOffer?.monthlyLeaseRate?.value || fallbackValues.monthlyLeaseRate,
						ppaTariffType: primaryOffer?.ppaTariffType
							? {
									label: primaryOffer?.ppaTariffType?.displayName,
									value: primaryOffer?.ppaTariffType?.ppaTariffType,
							  }
							: fallbackValues.ppaTariffType,
						ppaPricePerKwh: primaryOffer?.ppaPricePerKwh?.value || fallbackValues.ppaPricePerKwh,
						percentageOnGridTariff: primaryOffer?.percentageOnGridTariff
							? Big(primaryOffer.percentageOnGridTariff).times(100).toString()
							: fallbackValues.percentageOnGridTariff,
						floorTariff: primaryOffer?.floorTariff?.value || fallbackValues.floorTariff,
						ceilingTariff: primaryOffer?.ceilingTariff?.value || fallbackValues.ceilingTariff,
						escalationRateInPercent: primaryOffer?.escalationRateInPercent
							? Big(primaryOffer.escalationRateInPercent).times(100).toString()
							: fallbackValues.escalationRateInPercent,
						contractDuration: primaryOffer?.duration || fallbackValues.contractDuration,
						clientUpfrontPayment: primaryOffer?.clientUpfrontPayment?.value || fallbackValues.clientUpfrontPayment,
						solarUtilizationRate: primaryOffer?.solarUtilizationRate
							? Big(primaryOffer.solarUtilizationRate).times(100).toString()
							: fallbackValues.solarUtilizationRate,
						minimumPurchaseAmount: primaryOffer?.minimumPurchaseAmount
							? Big(primaryOffer.minimumPurchaseAmount).times(100).toString()
							: fallbackValues.minimumPurchaseAmount,
						discountOnGridTariff: primaryOffer?.discountOnGridTariff
							? Big(primaryOffer.discountOnGridTariff).times(100).toString()
							: fallbackValues.discountOnGridTariff,
						minimumWarrantedFunctionality: primaryOffer?.minimumWarrantedFunctionality
							? Big(primaryOffer.minimumWarrantedFunctionality).times(100).toString()
							: fallbackValues.minimumWarrantedFunctionality,
						billingPeriod: primaryOffer?.billingPeriod || fallbackValues.billingPeriod,

						contractParty: project?.contractPartyObjectByType
							? project?.contractPartyObjectByType?.SaaS
								? {
										label: project?.contractPartyObjectByType?.SaaS.name,
										value: project?.contractPartyObjectByType?.SaaS.id,
								  }
								: fallbackValues.contractParty
							: fallbackValues.contractParty,
						saasVersion: fallbackValues.saasVersion,
						longstop: fallbackValues.longstop,
						handoverPrice: fallbackValues.handoverPrice,
						bankAccount: fallbackValues.bankAccount,
						easementFee: fallbackValues.easementFee,

						projectedIrr: primaryOffer?.irr
							? Big(primaryOffer.irr).times(100).toString()
							: fallbackValues.projectedIrr,
						worstCaseIrr: primaryOffer?.worstCaseIrr
							? Big(primaryOffer.worstCaseIrr).times(100).toString()
							: fallbackValues.worstCaseIrr,
						saasSignatureDate: fallbackValues.saasSignatureDate,
						contractNumber: project?.externalId || fallbackValues.contractNumber,
						minimumDscr: primaryOffer?.minimumDscr || fallbackValues.minimumDscr,
						averageDscr: primaryOffer?.averageDscr || fallbackValues.averageDscr,
				  }
				: {
						...fallbackValues,
						systemSize: data?.systemSize || fallbackValues.systemSize,
						mountingTypes: data?.mountingTypes
							? data?.mountingTypes.map(({ mountingType, displayName }) => ({
									label: displayName,
									value: mountingType,
							  }))
							: fallbackValues.mountingTypes,
						epcPartner: data?.epcPartner
							? { label: data?.epcPartner.name, value: data?.epcPartner.id }
							: fallbackValues.epcPartner,
						epcVolume: data?.epcVolume?.value || fallbackValues.epcVolume,
						currency: currencies.default,
						yield: data?.yield || fallbackValues.yield,
						document: '',
						documentDisplayName: document?.displayName || fallbackValues.documentDisplayName,
						clientContractDocuments: data.documents || fallbackValues.clientContractDocuments,
						p90: data?.p90 || fallbackValues.p90,
						solarPvDegradation: data?.solarPvDegradation || fallbackValues.solarPvDegradation,
						annualOmPrice: data?.annualOmPrice?.value || fallbackValues.annualOmPrice,
						annualOmEscalationRate: data?.annualOmEscalationRate || fallbackValues.annualOmEscalationRate,
						saasOutageFactor: data?.saasOutageFactor || fallbackValues.saasOutageFactor,
						extraCapexExpected: data?.extraCapexExpected || fallbackValues.extraCapexExpected,
						extraOpexExpected: data?.extraOpexExpected || fallbackValues.extraOpexExpected,
						paymentGuaranteeType: data?.paymentGuaranteeType
							? {
									label: data.paymentGuaranteeType.displayName,
									value: data.paymentGuaranteeType.paymentGuaranteeType,
							  }
							: null || fallbackValues.paymentGuaranteeType,
						paymentGuaranteeAmount: data?.paymentGuaranteeAmount?.value || fallbackValues.paymentGuaranteeAmount,
						paymentGuaranteeDetails: data?.paymentGuaranteeDetails || fallbackValues.paymentGuaranteeDetails,

						contractType: data?.contractType
							? {
									label: data?.contractType.name,
									value: data?.contractType.id,
							  }
							: fallbackValues.contractType,
						saasCurrency: currencies.saas,
						monthlyLeaseRate: data?.monthlyLeaseRate?.value || fallbackValues.monthlyLeaseRate,
						ppaTariffType: data?.ppaTariffType
							? {
									label: data?.ppaTariffType?.displayName,
									value: data?.ppaTariffType?.ppaTariffType,
							  }
							: fallbackValues.ppaTariffType,
						ppaPricePerKwh: data?.ppaPricePerKwh?.value || fallbackValues.ppaPricePerKwh,
						percentageOnGridTariff: data?.percentageOnGridTariff || fallbackValues.percentageOnGridTariff,
						floorTariff: data?.floorTariff?.value || fallbackValues.floorTariff,
						ceilingTariff: data?.ceilingTariff?.value || fallbackValues.ceilingTariff,
						escalationRateInPercent: data?.escalationRateInPercent || fallbackValues.escalationRateInPercent,
						contractDuration: data?.contractDuration || fallbackValues.contractDuration,
						clientUpfrontPayment: data?.clientUpfrontPayment?.value || fallbackValues.clientUpfrontPayment,
						solarUtilizationRate: data?.solarUtilizationRate || fallbackValues.solarUtilizationRate,
						minimumPurchaseAmount: data?.minimumPurchaseAmount || fallbackValues.minimumPurchaseAmount,
						minimumWarrantedFunctionality:
							data?.minimumWarrantedFunctionality || fallbackValues.minimumWarrantedFunctionality,
						discountOnGridTariff: data?.discountOnGridTariff || fallbackValues.discountOnGridTariff,
						billingPeriod: data?.billingPeriod || fallbackValues.billingPeriod,

						contractParty: data?.contractParty
							? {
									label: data?.contractParty?.name,
									value: data?.contractParty?.id,
							  }
							: fallbackValues.contractParty,
						saasVersion: data?.saasVersion || fallbackValues.saasVersion,
						longstop: data?.longstop ? parseISO(data.longstop) : fallbackValues.longstop,
						handoverPrice: data?.handoverPrice?.value || fallbackValues.handoverPrice,
						bankAccount: data?.bankAccount
							? {
									label: data?.bankAccount?.name,
									value: data?.bankAccount?.id,
							  }
							: fallbackValues.bankAccount,
						easementFee: data?.easementFee?.value || fallbackValues.easementFee,

						projectedIrr: data?.projectedIrr || fallbackValues.projectedIrr,
						minimumDscr: data?.minimumDscr || fallbackValues.minimumDscr,
						averageDscr: data?.averageDscr || fallbackValues.averageDscr,
						worstCaseIrr: data?.worstCaseIrr || fallbackValues.worstCaseIrr,
						saasSignatureDate: data?.saasSignatureDate
							? parseISO(data?.saasSignatureDate)
							: fallbackValues.saasSignatureDate,
						contractNumber: data?.contractNumber || fallbackValues.contractNumber,
				  },
		[mode, data, primaryOffer, currencies.default, currencies.saas, project, document?.displayName],
	);

	const sectionFields = useMemo(
		() => ({
			projectDetails: [
				'systemSize',
				'mountingTypes',
				'epcPartner',
				'epcVolume',
				'currency',
				'yield',
				'p90',
				'solarPvDegradation',
				'annualOmPrice',
				'annualOmEscalationRate',
				'extraCapexExpected',
				'extraOpexExpected',
				'paymentGuaranteeType',
				'paymentGuaranteeAmount',
				'paymentGuaranteeDetails',
				'solarUtilizationRate',
			],
			comercialDetails: [
				'contractType',
				'saasCurrency',
				'monthlyLeaseRate',
				'ppaTariffType',
				'ppaPricePerKwh',
				'percentageOnGridTariff',
				'floorTariff',
				'ceilingTariff',
				'escalationRateInPercent',
				'contractDuration',
				'clientUpfrontPayment',
				'minimumPurchaseAmount',
				'discountOnGridTariff',
				'billingPeriod',
			],
			otherContractualTerms: [
				'contractParty',
				'saasVersion',
				'longstop',
				'handoverPrice',
				'bankAccount',
				'easementFee',
				'minimumWarrantedFunctionality',
			],
			ecoligoEconomicResults: [
				'projectedIrr',
				'minimumDscr',
				'averageDscr',
				'worstCaseIrr',
				'saasSignatureDate',
				'contractNumber',
			],
		}),
		[],
	);

	return {
		defaultValues,
		sectionFields,
	};
};

export default useClientContractOverlayValues;
